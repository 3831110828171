import React from "react";
import Container from 'react-bootstrap/Container';
import { styles } from "./UserListItem.component.style";

const UserListItem = ({
  user
}) => {
  return (
    <Container>
      <li key={user.id} style={styles.listRow}>
      <p style={{...styles.listRowText, width:"20%"}}>{user.user_name}</p>
        <p style={{...styles.listRowText, width:"20%"}}>{user.first_name}</p>
        <p style={{...styles.listRowText, width:"20%"}}>{user.last_name}</p>
        <p style={{...styles.listRowText, width:"20%"}}>{user.email}</p>
        <p style={{...styles.listRowText, width:"20%"}}>{user.city.name}</p>
        <p style={{...styles.listRowText, width:"20%"}}>{user.host ? "Host" : "Guest"}</p>
      </li>
    </Container>
  );
};

export default UserListItem;
