import React from 'react';
import { SocialIcon } from 'react-social-icons/component'
import 'react-social-icons/meetup'
import 'react-social-icons/x'
import 'react-social-icons/facebook'
import 'react-social-icons/instagram'
import 'react-social-icons/youtube'
import { styles }  from './SocialMedia.component.style';

const SocialMedia = () => {
    return (
      <div>
          
        <div style={styles.mainContainer}>
          <text style={styles.headerText}>Follow Us on </text>
          <SocialIcon url="https://www.facebook.com/profile.php?id=61567805852891" style={{ height: 30, width: 30, margin:15 }} />
          <SocialIcon url="https://www.instagram.com/eventyolthebayarea/" style={{ height: 30, width: 30, margin:15 }} />
          <SocialIcon url="https://x.com/eventyol197301" style={{ height: 30, width: 30, margin:15 }} />
          <SocialIcon url="https://www.youtube.com/@eventyol" style={{ height: 30, width: 30, margin:15  }} />
        </div>
        <div style={styles.copyRightRow}>
          <text style={styles.copyRightText}>This website includes images generated with the help of Hotpot.ai.</text>
        </div>
        <div style={styles.copyRightRow}>
          <text style={styles.copyRightText}>© 2024 Eventyol. All Rights Reserved</text>
        </div>
      </div>

    )
}

export default SocialMedia;
