const soccerRecImage1 = require('./soccer_rec_1.jpg');
const soccerRecImage2 = require('./soccer_rec_2.jpg');
const soccerRecImage3 = require('./soccer_rec_3.jpg');
const hikingRecImage1 = require('./hiking_rec_1.jpg');
const hikingRecImage2 = require('./hiking_rec_2.jpg');
const partyRecImage1 = require('./party_rec_1.jpg');
const surfingRecImage1 = require('./surfing_rec_1.jpg');
const surfingRecImage2 = require('./surfing_rec_2.jpg');
const soccerSquareImage1 = require('./soccer_square_1.jpg');
const soccerSquareImage2 = require('./soccer_square_2.jpg');
const soccerSquareImage3 = require('./soccer_square_3.jpg');
const hikingSquareImage1 = require('./hiking_square_1.jpg');
const hikingSquareImage2 = require('./hiking_square_2.jpg');
const campingSquareImage1 = require('./camping_square_1.jpg');
const campingSquareImage2 = require('./camping_square_2.jpg');
const partySquareImage1 = require('./party_square_1.jpg');
const surfingSquareImage1 = require('./surfing_square_1.jpg');
const surfingSquareImage2 = require('./surfing_square_2.jpg');
const homePageImage = require('./HomePage.png');
const mapPageImage = require('./MapPage.png');
const eventPageImage = require('./EventPage.png');
const contactPersonImage = require('./ContactPersonImage.jpg')


export const images = {
  soccerRecImage1,
  soccerRecImage2,
  soccerRecImage3,
  hikingRecImage1,
  hikingRecImage2,
  partyRecImage1,
  surfingRecImage1,
  surfingRecImage2,
  soccerSquareImage1,
  soccerSquareImage2,
  soccerSquareImage3,
  hikingSquareImage1,
  hikingSquareImage2,
  campingSquareImage1,
  campingSquareImage2,
  partySquareImage1,
  surfingSquareImage1,
  surfingSquareImage2,
  homePageImage,
  mapPageImage,
  eventPageImage,
  contactPersonImage
};