import { colors } from './../../constants/colors';
export const styles = {
  listRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    borderBottom: `1px solid ${colors.light_grey}`
  },
  listRowText: {
    width:"13%",
    textAlign: 'left',
    fontSize: 12,
  },
  actionColumn: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  actionButton: {
    margin: '2px'
  }
}