import React, {useState} from 'react';
import { Container} from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import {styles} from './PhotosSection2.component.style';

const PhotosSection2 = ({images}) => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
      setIndex(selectedIndex);
    };

    return(
    <Container fluid style={styles.mainContainer}>
      <Container fluid style={styles.imageContainer}>
        <Carousel activeIndex={index} controls={false} style={{width:"100%",padding: 0, margin:0,padding:0}} onSelect={handleSelect}>
        <Carousel.Item interval={2500}>
            <img src={images.soccerSquareImage2} alt="soccer" style={styles.imageStyle}  />
          </Carousel.Item>
          <Carousel.Item interval={2500} style={{width:"100%",padding: 0, margin:0}}>
            <img src={images.surfingSquareImage1} alt="soccer" style={styles.imageStyle}  />
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <img src={images.hikingSquareImage2} alt="soccer" style={styles.imageStyle}  />
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <img src={images.surfingSquareImage2} alt="soccer" style={styles.imageStyle}  />
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <img src={images.campingSquareImage1} alt="soccer" style={styles.imageStyle}  />
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <img src={images.campingSquareImage2} alt="soccer" style={styles.imageStyle}  />
          </Carousel.Item>
           <Carousel.Item interval={2500}>
            <img src={images.soccerSquareImage1} alt="soccer" style={styles.imageStyle}  />
          </Carousel.Item>
        </Carousel>
      </Container>
    </Container>
  )
}


export default PhotosSection2;