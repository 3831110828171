import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import { styles } from "./HostListItem.component.style";
import EditHostModal from "../EditHostModal";
const HostListItem = ({
  host
}) => {
  const [showEditHostModal, setShowEditHostModal] = useState(false);
  const [selectedHost, setSelectedHost] = useState(null);

  const handleEditClick = (host) => {
    setSelectedHost(host);
    setShowEditHostModal(true);
  };

  return (
    <Container>
      <li key={host.id} style={styles.listRow}>
        <p style={{...styles.listRowText, width:"20%"}}>{host.first_name}</p>
        <p style={{...styles.listRowText, width:"20%"}}>{host.last_name}</p>
        <p style={{...styles.listRowText, width:"60%"}}>{host.host_message}</p>
        <button onClick={() => handleEditClick(host)} className="btn btn-success">Edit</button>
      </li>
      {showEditHostModal && (
        <EditHostModal 
          showEditHostModal={showEditHostModal} 
          setShowEditHostModal={setShowEditHostModal}
          host={host}
        />
      )}
    </Container>
  );
};

export default HostListItem;
