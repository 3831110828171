import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';
import LocationListItem from "../LocationListItem";
import { styles } from "./LocationPanel.component.style";
import { fetchLocations } from "../../redux/features/location/locationSlice";
import CreateLocationModal from "../CreateLocationModal";

const LocationPanel = () => {
  const locations = useSelector((state) => state.location.locations);
  const dispatch = useDispatch();
  const [showCreateLocationModal, setShowCreateLocationModal] = useState(false);
  


  const locationListItems = locations.map((location) => (
    <LocationListItem key={location.id} location={location} />
    ));

  return (
    <Container>
      <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
        <p style={{fontSize:20}}>{locations.length} Locations</p>
        <button onClick={() => dispatch(fetchLocations())} className="btn btn-success">Reload Locations</button>
        <button onClick={() => setShowCreateLocationModal(true)} className="btn btn-success">Create Location</button>
      </div>
      <ul>
        <Container>
          <li style={styles.listRow}>
            <p style={{...styles.listRowText, width:"30%"}}>Name</p>
            <p style={{...styles.listRowText, width:"20%"}}>Type</p>
            <p style={{...styles.listRowText, width:"30%"}}>Adress</p>
            <p style={{...styles.listRowText, width:"10%"}}>City</p>
            <p style={{...styles.listRowText, width:"10%"}}>State</p>
          </li>
        </Container>
        {locationListItems}
      </ul>
      <CreateLocationModal showCreateLocationModal={showCreateLocationModal} setShowCreateLocationModal= {setShowCreateLocationModal}/>
    </Container>
  );
};

export default LocationPanel;
