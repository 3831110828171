import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { fetchRecommendations } from "../../redux/features/recommendation/recommendationSlice";
import Container from 'react-bootstrap/Container';
import { styles } from "./RecommendationPanel.component.style";
import RecommendationListItem from "../RecommendationListItem";

const RecommendationPanel = () => {
  const recommendations = useSelector((state) => state.recommendation.recommendations);
  const dispatch = useDispatch();

  // loop over events and display them in a list
  const recommendationsListItems = recommendations.map((recommendation) => (
    <RecommendationListItem key={recommendation.id} recommendation={recommendation} />
    ));


  return (
    <Container>
      <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
        <p style={{fontSize:20}}>{recommendations.length} Recommendations</p>
        <button onClick={() => dispatch(fetchRecommendations())} className="btn btn-success">Reload</button>
      </div>
      <ul>
        <Container>
          <li style={styles.listRow}>
            <p style={{...styles.listRowText, width:"20%"}}>Title</p>
            <p style={{...styles.listRowText, width:"20%"}}>Time</p>
            <p style={{...styles.listRowText, width:"20%"}}>Location</p>
            <p style={{...styles.listRowText, width:"40%"}}>Description</p>
          </li>
        </Container>
        {recommendationsListItems}
      </ul>
    </Container>
  );
};

export default RecommendationPanel;
