export const skillLevelDropdownData = [
  {label: "All Levels", value: "All Levels"},
  {label: "Recreational", value: "Recreational"},
  {label: "Beginner", value: "Beginner"},
  {label: "Intermediate", value: "Intermediate"},
  {label: "Advanced", value: "Advanced"},
];

export const gameTypeDropdownData = [
  {label: "Soccer", value: "Soccer"},
  {label: "Basketball", value: "Basketball"},
  {label: "Volleyball", value: "Volleyball"},
  {label: "Hiking", value: "Hiking"},
  {label: "Biking", value: "Biking"},
  {label: "Chess", value: "Chess"},
  {label: "Surfing", value: "Surfing"},
  {label: "Camping", value: "Camping"},
  {label: "Social Events", value: "Social Events"},
];