// generate a function takes a date string and returns a formatted date string
export const localReadableDate = (date) => {
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const dateObj = new Date(date);
  const newDate = new Date(dateObj.getTime() + dateObj.getTimezoneOffset() * 60000);
  return newDate.toLocaleDateString(undefined, options);
};


// generate function to takes utc datetime string and returns local time string human radable
export const localReadableTime = (dateTime) => {
  const options = {
    hour: 'numeric',
    minute: 'numeric',
  };
  const dateObj = new Date(dateTime);
  return dateObj.toLocaleTimeString(undefined, options);
};

export const getTimePartForTimePicker = (utcTimeString) => {
  // Create a Date object from the UTC time string
  const utcDate = new Date(utcTimeString);
  
  // get time part like "21:30"
  return utcDate.toTimeString().substring(0, 5);
};