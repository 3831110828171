import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import { clearError } from '../../redux/features/event/eventSlice';

const ErrorDisplay = ({error}) => {
  const dispatch = useDispatch();
  //const error = useSelector((state) => state.event.error);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        dispatch(clearError());
      }, 5000); // Clear error after 5 seconds

      return () => clearTimeout(timer); // Cleanup timer on component unmount
    }
  }, [error, dispatch]);

  if (!error) {
    return null;
  }

  return (
    <Alert variant="danger">
      {error.message}
    </Alert>
  );
};

export default ErrorDisplay;