import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import { styles } from "./EventListItem.component.style";
import { localReadableDate, localReadableTime } from "../../utils/dateUtils";
import EditEventModal from "../EditEventModal";
import EventModal from "../EventModal";
import { deleteEvent } from "../../redux/features/event/eventSlice";
import { useDispatch } from 'react-redux';

const EventsListItem = ({
  event
}) => {

  const [showEditEventModal, setShowEditEventModal] = useState(false);
  const [showEventModal, setShowEventModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const dispatch = useDispatch();

  const handleDetailsClick = (event) => {
    setSelectedEvent(event);
    setShowEventModal(true);
  }

  const handleDeleteClick = (event) => {
    // if event is_active is true, then we can't delete it
    if (event.is_active) {
      alert("Cannot delete active events, Please set event to inactive if you want to hide it from users then delete it.");
      return;
    }
    const currentDate = new Date();
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);
    const eventDate = new Date(event.date);
    if (eventDate >= yesterday) {
      alert("Cannot delete future events, Please set event to inactive if you want to hide it from users or contact the admin to delete it.");
      return;
    }
    dispatch(deleteEvent(event.id));
  };

  const eventDate = localReadableDate(event.date);
  const startTime = localReadableTime(event.start_time);
  const endTime = localReadableTime(event.end_time);
  
  return (
    <>
      <Container fluid>
        <li key={event.id} style={styles.listRow}>
          <p style={{...styles.listRowText, width:"10%", fontWeight:"bold", color: event.active ? "green" : "lightgrey"}}>{event.active ? "ACTIVE" : "INACTIVE"}</p>
          <p style={{...styles.listRowText, width:"20%"}}>{event.game_name}</p>
          <p style={{...styles.listRowText, width:"20%"}}>{event.event_type.name}</p>
          <p style={{...styles.listRowText, width:"20%"}}>{eventDate}</p>
          <p style={{...styles.listRowText, width:"10%"}}>{startTime}</p>
          <p style={{...styles.listRowText, width:"10%"}}>{endTime}</p>
          <div style={{...styles.actionColumn, width:"10%"}}>
            <div style={styles.actionButton}>
              <button onClick={() => handleDetailsClick(event)} className="btn btn-info">Details</button>
            </div>
          </div>
        </li>
      </Container>
      {selectedEvent && (
        <EventModal
          showEventModal={showEventModal}
          setShowEventModal={setShowEventModal}
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent}
          setShowEditEventModal={setShowEditEventModal}
          handleDeleteClick={handleDeleteClick}
        />
      )}
      {selectedEvent && (
        <EditEventModal
          showEditEventModal={showEditEventModal}
          setShowEditEventModal={setShowEditEventModal}
          event={selectedEvent}
        />
      )}
    </>
  );
};

export default EventsListItem;
