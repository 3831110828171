import React, { useState } from 'react';
import { Modal, Button, Container, Row, Col, Form } from 'react-bootstrap';
import './../../styles/styles.css';
import { useSelector, useDispatch  } from 'react-redux';
import { editHost } from '../../redux/features/host/hostSlice';

function EditHostModal({
  host,
  showEditHostModal,
  setShowEditHostModal,
}) {
  const dispatch = useDispatch();
  const cities = useSelector((state) => state.admin.cities);
  const users = useSelector((state) => state.user.users);

  const [first_name, setFirstName] = useState(host.first_name);
  const [last_name, setLastName] = useState(host.last_name);
  const [host_message, setHostMessage] = useState(host.host_message);
  const [city_id, setCityId] = useState(host.city_id);
  const [user_id, setUserId] = useState(host.user_id);

  const cityOptions = cities.map((city) => (
    <option key={city.id} value={city.id}>
      {city.name + ", " + city.state}
    </option>
  ));

  const userOptions = users.map((user) => (
    <option key={user.id} value={user.id}>
      {user.first_name + " " + user.last_name}
    </option>
  ));

  const handleClose = () => {
    setShowEditHostModal(false)
  };

  const handleEditHost = () => {

    const requestBody = {
      id: host.id,
      first_name: first_name,
      last_name: last_name,
      host_message: host_message,
      city_id: +city_id,
      user_id: +user_id
    };
    dispatch(editHost(requestBody));
    setShowEditHostModal(false);
  };


  return (
    <>
      <Modal show={showEditHostModal} fullscreen={true} >
        <Modal.Header>
          <Modal.Title>Create Host</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row style={{marginBottom:20}}>
              <Col xs={12} md={6}>
                <Form.Label htmlFor="inputPassword5">User</Form.Label>
                <Form.Select
                  value={user_id}
                  onChange={(e) => setUserId(e.target.value)}
                >
                  {userOptions}
                </Form.Select>
              </Col>
              <Col xs={12} md={6}>
                <Form.Label htmlFor="inputPassword5">City</Form.Label>
                <Form.Select
                  value={city_id}
                  onChange={(e) => setCityId(e.target.value)}
                >
                  {cityOptions}
                </Form.Select>
              </Col>
            </Row>

            <Row style={{marginBottom:20}}>
              <Col xs={21} md={6}>
                <Form.Label htmlFor="inputPassword5">First Name</Form.Label>
                <Form.Control
                  type="text"
                  value={first_name}
                  placeholder='Enter First Name'
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Col>
              <Col xs={12} md={6}>
                <Form.Label htmlFor="inputPassword5">Last Name</Form.Label>
                <Form.Control
                  type="text"
                  value={last_name}
                  placeholder='Enter Last Name'
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Col>
            </Row>

            <Row style={{marginBottom:20}}>
              <Col xs={12} md={12}>
                <Form.Label htmlFor="inputPassword5">Host Message</Form.Label>
                <Form.Control
                  type="text"
                  value={host_message}
                  as="textarea"
                  rows={5}
                  placeholder='Enter Host Message'
                  onChange={(e) => setHostMessage(e.target.value)}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditHost}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditHostModal;