import { colors } from '../../constants/colors';
export const styles = {
  listRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    borderBottom: `1px solid ${colors.light_grey}`
  },
  listRowText: {
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 'bold',
  },
  actionColumn: {
    textAlign: 'left',
    fontWeight: 'bold',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
}