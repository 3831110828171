import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import { styles } from "./LocationListItem.component.style";
import EditLocationModal from "../EditLocationModal";

const LocationListItem = ({
  location
}) => {
  const [showEditLocationModal, setShowEditLocationModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleEditClick = (location) => {
    setSelectedLocation(location);
    setShowEditLocationModal(true);
  };
  return (
    <Container>
      <li key={location.id} style={styles.listRow}>
        <p style={{...styles.listRowText, width:"30%"}}>{location.location_name}</p>
        <p style={{...styles.listRowText, width:"20%"}}>{location.location_type}</p>
        <p style={{...styles.listRowText, width:"30%"}}>{location.location_address}</p>
        <p style={{...styles.listRowText, width:"10%"}}>{location.location_city}</p>
        <p style={{...styles.listRowText, width:"10%"}}>{location.location_state}</p>
        <div style={styles.listRowText}>
          <button variant="primary" onClick={() => handleEditClick(location)} className="btn btn-success">Edit</button>
        </div>
      </li>
      {selectedLocation && (
        <EditLocationModal
          showEditLocationModal={showEditLocationModal}
          setShowEditLocationModal={setShowEditLocationModal}
          location={selectedLocation}
        />
      )}
    </Container>
  );
};

export default LocationListItem;
