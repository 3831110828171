import React, {useState} from 'react';
import { Container} from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import {styles} from './PhotosSection.component.style';

const PhotosSection = ({images}) => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
      setIndex(selectedIndex);
    };

    return(
    <Container fluid style={styles.mainContainer}>
      <Container fluid style={styles.imageContainer}>
        <Carousel activeIndex={index} controls={false} style={{width:"100%",padding: 0, margin:0,padding:0}} onSelect={handleSelect}>
          <Carousel.Item interval={2500} style={{width:"100%",padding: 0, margin:0}}>
            <img src={images.surfingRecImage1} alt="soccer" style={styles.imageStyle}  />
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <img src={images.hikingRecImage2} alt="soccer" style={styles.imageStyle}  />
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <img src={images.partyRecImage1} alt="soccer" style={styles.imageStyle}  />
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <img src={images.surfingRecImage2} alt="soccer" style={styles.imageStyle}  />
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <img src={images.hikingRecImage1} alt="soccer" style={styles.imageStyle}  />
          </Carousel.Item>
          <Carousel.Item interval={2500}>
            <img src={images.soccerRecImage3} alt="soccer" style={styles.imageStyle}  />
          </Carousel.Item>
        </Carousel>
      </Container>
    </Container>
  )
}


export default PhotosSection;