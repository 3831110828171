import React from "react";
import Container from 'react-bootstrap/Container';
import { styles } from "./MessageListItem.component.style";

const MessageListItem = ({
  message
}) => {

  return (
    <Container>
      <li key={message.id} style={styles.listRow}>
        <p style={{...styles.listRowText, width:"20%"}}>{message.first_name + "" + message.last_name}</p>
        <p style={{...styles.listRowText, width:"20%"}}>{message.email}</p>
        <p style={{...styles.listRowText, width:"60%"}}>{message.description}</p>
      </li>
    </Container>
  );
};

export default MessageListItem;
