export function emailValidator(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function capitalizeFirstLetter(string) {
  if(!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function validatePhoneNumber(phone) {
  const re = /^\d{10}$/;
  return re.test(phone);
}

export function validatePassword(password) {
  if (!password) {
    return "Password is required";
  }
  if (password.length < 6 || password.length > 20) {
    return "Password must be between 6 and 20 characters";
  }
  if (!/(?=.*\d)/.test(password)) {
    return "Password must contain at least one numeric digit";
  }
  if (!/(?=.*[a-z])/.test(password)) {
    return "Password must contain at least one lowercase letter";
  }
  if (!/(?=.*[A-Z])/.test(password)) {
    return "Password must contain at least one uppercase letter";
  }
  return null; // No error
}

export  function validateEventRequiredFields(requestBody) {
  if (requestBody.game_name === "") {
    alert("Please enter a game name");
    return false;
  }
  if (requestBody.game_type === "") {
    alert("Please select a game type");
    return false;
  }
  if (requestBody.skill_level === "") {
    alert("Please select a skill level");
    return false;
  }
  // if max_player is not a number aor less than 1
  if (isNaN(requestBody.max_player) || requestBody.max_players < 1) {
    alert("Please enter a valid number for max players");
    return false;
  }
  // if price is not a number and less than 0 not 2 decimal places
  if(isNaN(requestBody.price) || requestBody.price < 0 ) {
    alert("Please enter a valid price, it shoudl be a number with 2 decimal places like 10.99");
    return false;
  }
  // if location_id is not a number or less than 1
  if (isNaN(requestBody.location_id) || requestBody.location_id < 1) {
    alert("Please select a location");
    return false;
  }
  // if city_id is not a number or less than 1
  if (isNaN(requestBody.city_id) || requestBody.city_id < 1) {
    alert("Please select a city");
    return false;
  }
  // if host_id is not a number or less than 1
  if (isNaN(requestBody.host_id) || requestBody.host_id < 1) {
    alert("Please select a host");
    return false;
  }
  if (requestBody.date === "") {
    alert("Please select event date");
    return false;
  }
  if (requestBody.start_time === "") {
    alert("Please select event start time");
    return false;
  }
  if (requestBody.end_time === "") {
    alert("Please select event end time");
    return false;
  }
  return true;
};


export function validateLocationRequiredFields(requestBody) {
  if (requestBody.location_name === "") {
    alert("Please enter a location name");
    return false;
  }
  if (requestBody.city_id === "") {
    alert("Please select a city");
    return false;
  }
  if (requestBody.location_type === "") {
    alert("Please select a location type");
    return false;
  }
  if (requestBody.location_address === "") {
    alert("Please enter a location address");
    return false;
  }
  if (requestBody.location_city === "") {
    alert("Please enter a location city");
    return false;
  }
  if (requestBody.location_state === "") {
    alert("Please enter a location state");
    return false;
  }
  if (requestBody.location_zip === "") {
    alert("Please enter a location zip");
    return false;
  }
  if (isNaN(requestBody.lat) || isNaN(requestBody.long) ||
    typeof requestBody.lat !== "number" || typeof requestBody.long !== "number" || 
    requestBody.lat == 0 || requestBody.long == 0 ) {
    alert("Please enter numeric latitude and longitude values between -90 and 90 for latitude, and -180 and 180 for longitude.");
    return false;
  }
  return true;
};