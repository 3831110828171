import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginAdmin} from './../../redux/features/admin/adminSlice';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { colors } from '../../constants/colors';

const Login = () => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const admin = useSelector((state) => state.admin);

  useEffect(() => {
    if (admin.isAuthenticated) {
      navigate('/admin');
    }
  }, [admin.isAuthenticated, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginAdmin({ email, password }));
  };

  return (
    <>
      
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", backgroundColor:colors.dark_blue, height:"100vh"}}>
        <div style={{alignItems: "center", width:"40%", maxWidth:400}}>
          <p style={{color:colors.white}}>Login as an Administrator</p>
          <FloatingLabel
            controlId="floatingInput"
            label="Email address"
            className="mb-3"
          >
             <Form.Control
              type="email"
              placeholder="name@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingPassword" label="Password">
            <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
          </FloatingLabel>
          <div style={{display: "flex", justifyContent: "center", alignItems: "center",marginTop:20}}>
            <Button variant="success" type="submit" onClick={handleSubmit} >
              Login
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}


export default Login;