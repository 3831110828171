import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';
import { fetchHosts } from "../../redux/features/host/hostSlice";
import HostListItem from "../HostListItem";
import CreateHostModal from "../CreateHostModal";
import { styles } from "./HostPanel.component.style";

const HostPanel = () => {
  const hosts = useSelector((state) => state.host.hosts);
  const [showCreateHostModal, setShowCreateHostModal] = useState(false);
  const dispatch = useDispatch();
  // loop over events and display them in a list
  const hostListItems = hosts.map((host) => (
    <HostListItem key={host.id} host={host} />
    ));


  return (
    <Container>
      <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
        <p style={{fontSize:20}}>{hosts.length} Hosts</p>
        <button onClick={() => dispatch(fetchHosts())} className="btn btn-success">Reload</button>
        <button onClick={() => setShowCreateHostModal(true)} className="btn btn-success">Create Host</button>
      </div>
      <ul>
        <Container>
          <li style={styles.listRow}>
            <p style={{...styles.listRowText, width:"20%"}}>First Name</p>
            <p style={{...styles.listRowText, width:"20%"}}>Last Name</p>
            <p style={{...styles.listRowText, width:"60%"}}>Host Message</p>
          </li>
        </Container>
        {hostListItems}
      </ul>
      {showCreateHostModal && (
        <CreateHostModal 
          showCreateHostModal={showCreateHostModal} 
          setShowCreateHostModal={setShowCreateHostModal}
        />
      )}
    </Container>
  );
};

export default HostPanel;
