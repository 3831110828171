import React, { useState } from 'react';
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import { localReadableDate} from './../../utils/dateUtils';
import './../../styles/styles.css';
import { useSelector } from 'react-redux';
import { getTimePartForTimePicker } from '../../utils/dateUtils';
import { addUserToEvent, removeUserFromEvent } from './../../redux/features/event/eventSlice';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import ImageSection from './ImageSection';

function EventModal({
  selectedEvent,
  showEventModal,
  setShowEventModal,
  setShowEditEventModal,
  setSelectedEvent,
  handleDeleteClick
}) {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user.users);
  const event = useSelector((state) => state.event.events.find((event) => event.id === selectedEvent.id));
  const localStartTime = getTimePartForTimePicker(event.start_time);
  const localEndTime = getTimePartForTimePicker(event.end_time);
  const radableDate = localReadableDate(event.date);
  const [selectedUser, setSelectedUser] = useState(null);
  
  const handleClose = () => {
    setShowEventModal(false)
  };

  const addUser = () => {
    if(selectedUser === null){
      alert("Please select a user to add to the event");
      return;
    }
    const requestBody = {
      event_id: event.id,
      user_id: selectedUser.value
    }
    dispatch(addUserToEvent(requestBody));
    setSelectedUser(null);
  }

  const handleDeleteUser = (user) => {
    const requestBody = {
      id: event.id,
      user_id: user.id
    }
    dispatch(removeUserFromEvent(requestBody));
  }

  const handleEditClick = (event) => {
    setSelectedEvent(event);
    setShowEditEventModal(true);
  };

  const options = users.map((user) => {
    return { value: user.id, label: user.first_name + " " + user.last_name + " --- Email: " + user.email }
  });

  const registeredUserList = event.users.map((user) => {
    return (
    <div style={{display:"flex", justifyContent:"space-between", borderBottomStyle:"solid", borderBottomWidth:1, padding:5, marginTop:5}}>
      <div>
        <p >{user.first_name} {user.last_name}</p>
        <p >{user.email}</p>
      </div>
      <button 
        style={{
          backgroundColor:"red",
          width:70,
          height:30,
          marginTop:10,
          color:"white",
          fontSize:12,
          }}
          onClick={() => handleDeleteUser(user)}>Remove</button>
    </div>
  )
  });

  return (
    <>
      <Modal show={showEventModal} fullscreen={true} >
        <Modal.Header>
          <Modal.Title>{ event.game_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row style={{display:"flex", justifyContent:"space-between" }}>
              <button variant="primary" onClick={() => handleEditClick(event)} className="btn btn-success" style={{width:150, marginBottom:20}}>Edit</button>
              <Button variant="secondary" onClick={handleClose} style={{width:150, marginBottom:20}}>
                Close
              </Button>
            </Row>
            <Row style={{marginBottom:20, borderBottomStyle:"solid", borderBottomWidth:2}}>
              <p style={{fontSize:20, fontWeight:"bold"}}>Event Information</p>
            </Row>
            <Row style={{marginBottom:20, borderBottomStyle:"solid", borderBottomWidth:2}}>
              <Col xs={12} md={6} style={{padding:10}}>
                  <p>Event Type: {event.event_type.name}</p>
                  <p>Skill Level: {event.skill_level}</p>
                  <p>Max Players: {event.max_player}</p>
                  <p>Price: ${event.price}</p>
                  <p>Location: {event.location.location_name}</p>
                  <p>Is Free: {event.is_free ? "YES" : "NO"}</p>
                  <p>Active: {event.active ? "ACTIVE" : "INACTIVE"}</p>
                  <p>City: {event.city.name}</p>
                  <p>Host: {event.host.first_name} {event.host.last_name}</p>
              </Col>
              <Col xs={12} md={6} style={{padding:10}}>
                  <p>Date: {radableDate}</p>
                  <p>Start Time: {localStartTime}</p>
                  <p>End Time: {localEndTime}</p>
                  <p>Description First Title: {event.descriptionFirstTitle}</p>
                  <p>Description First Text: {event.descriptionFirstText}</p>
                  <p>Description Second Title: {event.descriptionSecondTitle}</p>
                  <p>Description Second Text: {event.descriptionSecondText}</p>
                  <p>Description Third Title: {event.descriptionThirdTitle}</p>
                  <p>Description Third Text: {event.descriptionThirdText}</p>
              </Col>
             </Row>

             <Row style={{marginBottom:20, borderBottomStyle:"solid", borderBottomWidth:2}}>
              <Col xs={12} md={6} style={{padding:10}}>
                  <p style={{fontSize:20, fontWeight:"bold"}}>Registered Users</p>
                  <ul>
                    {registeredUserList}
                  </ul>
              </Col>

              <Col xs={12} md={6} style={{padding:10}}>
                <p style={{fontSize:20, fontWeight:"bold", textAlign:"center"}}>Select and Add User</p>
                <Select options={options} value={selectedUser} onChange={setSelectedUser} />
                <div style={{padding:10, textAlign:"center"}}>
                  <Button variant="primary" onClick={()=>addUser()}>Add User</Button>
                </div>
              </Col>
             </Row>

             <ImageSection event={event} />
            
             <Row style={{display:"flex", justifyContent:"space-between" }}>
                <button onClick={() => handleDeleteClick(event)} className="btn btn-danger" style={{width:150}}>Delete Event</button>
              </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EventModal;