import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { fetchMessages } from "../../redux/features/message/messageSlice";
import Container from 'react-bootstrap/Container';
import { styles } from "./MessagePanel.component.style";
import MessageListItem from "../MessageListItem";

const MessagePanel = () => {
  const messages = useSelector((state) => state.message.messages);
  const dispatch = useDispatch();

  // loop over events and display them in a list
  const messagesListItems = messages.map((message) => (
    <MessageListItem key={message.id} message={message} />
    ));

  return (
    <Container>
      <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
        <p style={{fontSize:20}}>{messages.length} Contact Messages</p>
        <button onClick={() => dispatch(fetchMessages())} className="btn btn-success">Reload</button>
      </div>
      <ul>
        <Container>
          <li style={styles.listRow}>
            <p style={{...styles.listRowText, width:"20%"}}>Name</p>
            <p style={{...styles.listRowText, width:"20%"}}>Email</p>
            <p style={{...styles.listRowText, width:"60%"}}>Description</p>
          </li>
        </Container>
        {messagesListItems}
      </ul>
    </Container>
  );
};

export default MessagePanel;
