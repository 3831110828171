import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { fetchUsers } from "../../redux/features/user/userSlice";
import Container from 'react-bootstrap/Container';
import { styles } from "./UserPanel.component.style";
import UserListItem from "../UserListItem";

const UserPanel = () => {
  const users = useSelector((state) => state.user.users);
  const dispatch = useDispatch();

  // loop over events and display them in a list
  const usersListItems = users.map((user) => (
    <UserListItem key={user.id} user={user} />
    ));

  return (
    <Container>
      <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
        <p style={{fontSize:20}}>{users.length} Users</p>
        <button onClick={() => dispatch(fetchUsers())} className="btn btn-success">Reload</button>
      </div>
      <ul>
        <Container>
          <li style={styles.listRow}>
            <p style={{...styles.listRowText, width:"20%"}}>Nick Name</p>
            <p style={{...styles.listRowText, width:"20%"}}>First Name</p>
            <p style={{...styles.listRowText, width:"20%"}}>Last Name</p>
            <p style={{...styles.listRowText, width:"20%"}}>Email</p>
            <p style={{...styles.listRowText, width:"20%"}}>City</p>
            <p style={{...styles.listRowText, width:"20%"}}>Role</p>
          </li>
        </Container>
        {usersListItems}
      </ul>
    </Container>
  );
};

export default UserPanel;
