import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AdminNavbar from '../../components/AdminNavbar';
import EventsPanel from '../../components/EventsPanel';
import HostPanel from '../../components/HostPanel';
import RecommendationPanel from '../../components/RecommendationPanel';
import MessagePanel from '../../components/MessagePanel';
import UserPanel from '../../components/UserPanel';
import LocationPanel from '../../components/LocationPanel';
import { logoutAdmin, clearState } from './../../redux/features/admin/adminSlice';
import { persistor } from './../../redux/store';

const AdminPanel = () => {

  const admin = useSelector((state) => state.admin);
  const [selectedItem, setSelectedItem] = useState('Events');
  const [timeLeft, setTimeLeft] = useState(2 * 60 * 60); // 2 hours in seconds
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!admin.isAuthenticated) {
      navigate('/login');
    }
  }, [admin.isAuthenticated, dispatch]);

useEffect(() => {
    const storedStartTime = localStorage.getItem('sessionStartTime');
    const currentTime = Date.now();
    let initialTimeLeft = 2 * 60 * 60; // 2 hours in seconds

    if (storedStartTime) {
      const elapsedTime = Math.floor((currentTime - parseInt(storedStartTime, 10)) / 1000);
      initialTimeLeft = Math.max(2 * 60 * 60 - elapsedTime, 0);
    } else {
      localStorage.setItem('sessionStartTime', currentTime.toString());
    }

    setTimeLeft(initialTimeLeft);

    const interval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(interval);
          handleSessionExpire();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000); // Update every second

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const handleSessionExpire = async () => {

    dispatch(logoutAdmin());
    dispatch(clearState());
    await persistor.purge();
    navigate('/login');
  };

  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);
    return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
  };

  return (
    <React.Fragment>
      <AdminNavbar setSelectedItem={setSelectedItem}/>
      <div style={{ textAlign: "center" }}>
        <p style={{ textAlign:"right", paddingRight:"5%"}}>Session expires in {formatTime(timeLeft)}</p>
        {selectedItem === 'Events' && <EventsPanel />}
        {selectedItem === 'Hosts' && <HostPanel />}
        {selectedItem === 'Locations' && <LocationPanel />}
        {selectedItem === 'Users' && <UserPanel />}
        {selectedItem === 'Recomendations' && <RecommendationPanel />}
        {selectedItem === 'Messages' && <MessagePanel />}

      </div>
    </React.Fragment>
  );
}


export default AdminPanel;