import { colors } from '../../constants/colors';
export const styles = {
  downloadButton:{
    zIndex:2,
    height:40,
    width:150,
    color: "white",
    fontSize: 12,
    borderStyle: "none",
    width: "100%",
    maxWidth: "150px",
    backgroundColor: colors.button_orange,
  },
  logoContainer:{
    position: "absolute",
    zIndex: 3,
    top: 20,
    left: "5%",
  },
}