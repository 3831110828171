import React from "react";
import Container from 'react-bootstrap/Container';
import { styles } from "./RecommendationListItem.component.style";

const RecommendationListItem = ({
  recommendation
}) => {

  return (
    <Container>
      <li key={recommendation.id} style={styles.listRow}>
        <p style={{...styles.listRowText, width:"20%"}}>{recommendation.title}</p>
        <p style={{...styles.listRowText, width:"20%"}}>{recommendation.time}</p>
        <p style={{...styles.listRowText, width:"20%"}}>{recommendation.location}</p>
        <p style={{...styles.listRowText, width:"40%"}}>{recommendation.description}</p>
      </li>
    </Container>
  );
};

export default RecommendationListItem;
