import React, {  useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';
import EventsListItem from "../EventListItem";
import { styles } from "./EventsPanel.component.style";
import { fetchEvents } from "../../redux/features/event/eventSlice";
import CreateEventModal from "../CreateEventModal";
import ErrorDisplay from "./../ErrorDisplay";

const EventsPanel = () => {
  const dispatch = useDispatch();
  const events = useSelector((state) => state.event.events);
  const error = useSelector((state) => state.event.error);
  const [showCreateEventModal, setShowCreateEventModal] = useState(false);

  const createButtonHandler = () => {
    setShowCreateEventModal(true);
  };

  // loop over events and display them in a list
  const eventListItems = events.map((event) => (
    <EventsListItem key={event.id} event={event} />
    ));


  return (
    <Container fluid>
      <ErrorDisplay error={error}/>
      <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", padding:50}}>
        <button className="btn btn-success" onClick={createButtonHandler}>Create Event</button>
        <p style={{fontSize:20}}>{events.length} Upcoming Events</p>
        <button className="btn btn-success" onClick={() => dispatch(fetchEvents())} >Reload</button>
      </div>

      <ul>
        <Container fluid>
          <li style={styles.listRow}>
            <p style={{...styles.listRowText, width:"10%"}}>Status</p>
            <p style={{...styles.listRowText, width:"20%"}}>Event Name</p>
            <p style={{...styles.listRowText, width:"20%"}}>Event Type</p>
            <p style={{...styles.listRowText, width:"20%"}}>Date</p>
            <p style={{...styles.listRowText, width:"10%"}}>Start Time</p>
            <p style={{...styles.listRowText, width:"10%"}}>End Time</p>
            <p style={{...styles.actionColumn, width:"10%"}}>Actions</p>
          </li>
        </Container>
        {eventListItems}
      </ul>
      {showCreateEventModal && (
        <CreateEventModal 
          showCreateEventModal={showCreateEventModal} 
          setShowCreateEventModal={setShowCreateEventModal}
        />
      )}
    </Container>
  );
};

export default EventsPanel;
