import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../axiosInstance';

// fetch all recommendations
export const fetchRecommendations = createAsyncThunk(
  'admin/fetchRecommendations',
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.admin.authToken; // Adjust according to your state structure

      const response = await axiosInstance.get('/admin/recommendations', {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      return response.data;
    } catch (error) {
      // TO DO: Handle error properly
      alert(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  recommendations: [],
  status: 'idle',
  error: null
};

export const recommendationSlice = createSlice({
  name: 'recommendation',
  initialState,
  reducers: {
    clearRecommendations: (state) => {
      state.recommendations = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecommendations.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRecommendations.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.recommendations = action.payload;
      })
      .addCase(fetchRecommendations.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  }
});

export const { clearRecommendations } = recommendationSlice.actions;

export default recommendationSlice.reducer;
