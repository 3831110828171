import React, { useState } from "react";
import { Button, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { addImageToEvent, deleteImageFromEvent } from './../../../redux/features/event/eventSlice';

function ImageSection({ event }) {
  const dispatch = useDispatch();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [selectedFile, setSelectedFile] = useState(null);

  const addImage = () => {
    if (!selectedFile) {
      return;
    }

    // check if the file is in .jpg format
    if (selectedFile.type !== "image/jpeg") {
      alert("Please upload a .jpg file");
      return;
    }

    // check file size if it is greater than 500kb then return
    if (selectedFile.size > 500000) {
      alert("Please upload a file less than 500kb, you can use online image resizers to reduce the size of the image");
      return;
    }

    const formData = new FormData();
    formData.append('image', selectedFile);
    formData.append('id', event.id);
    dispatch(addImageToEvent(formData));
    setSelectedFile(null);
  }

  const deleteImage = (image) => {
    dispatch(deleteImageFromEvent({id: event.id, image_id: image.id}));
  }

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  return (
    <Row style={{marginBottom:20, borderBottomStyle:"solid", borderBottomWidth:2}}>
      <Row style={{display:'flex', flexDirection:"row"}}>
        <p style={{fontSize:20, fontWeight:"bold", marginBottom:20, width:250}}>Event Images</p>
        <input type="file" onChange={handleFileChange} style={{margin:5, width:250}}/>
        <Button 
          variant="primary"
          style={{width:200, marginBottom:10}}
          onClick={addImage}
          >Add Image</Button>
      </Row>
      <Row>
      {event.images.map((image) => {
        return (
          <Col key={image.id} xs={12} md={6} style={{padding:10, width:250}}>
            <img src={`${apiUrl}${image.url}`} style={{width:200, height:112}}/>
            <Button 
              width={200}
              variant="danger"
              style={{width:200, marginTop:10}}
              onClick={() => deleteImage(image)}
            >Delete Image</Button>
          </Col>
        )
        }
      )}
      </Row>
    </Row>
  );
}

export default ImageSection;