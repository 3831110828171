import { colors } from './../../constants/colors';

export const styles = {
  headerText:{
    color: colors.white,
    fontSize: 12,
    textAlign: 'center',
    fontWeight: 'bold',
    backgroundColor: colors.black,
  },
  mainContainer:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    backgroundColor: colors.black,
  },
  copyRightRow:{
    backgroundColor: colors.black,
    paddingTop: 15,
    width: '100%',
    textAlign: 'center',
    height: 50,
  },
  copyRightText:{
    color: colors.white,
    fontSize: 12,
    textAlign: 'center',
  },
}