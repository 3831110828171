import React, { useState } from 'react';
import { Modal, Button, Container, Row, Col, Form } from 'react-bootstrap';
import './../../styles/styles.css';
import { useSelector, useDispatch  } from 'react-redux';
import { createHost } from '../../redux/features/host/hostSlice';

function CreateHostModal({
  showCreateHostModal,
  setShowCreateHostModal,
}) {
  const dispatch = useDispatch();
  const cities = useSelector((state) => state.admin.cities);
  const users = useSelector((state) => state.user.users);

  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [host_message, setHostMessage] = useState("");
  const [city_id, setCityId] = useState(cities ? cities[0].id : "");
  const [user_id, setUserId] = useState(users ? users[0].id : "");

  const cityOptions = cities.map((city) => (
    <option key={city.id} value={city.id}>
      {city.name + ", " + city.state}
    </option>
  ));

  const userOptions = users.map((user) => (
    <option key={user.id} value={user.id}>
      {user.first_name + " " + user.last_name}
    </option>
  ));

  const handleClose = () => {
    setShowCreateHostModal(false)
  };

  const handleCreateHost = () => {

    const requestBody = {
      first_name: first_name,
      last_name: last_name,
      host_message: host_message,
      city_id: city_id,
      user_id: user_id
    };

    dispatch(createHost(requestBody));
    setShowCreateHostModal(false);
  };


  return (
    <>
      <Modal show={showCreateHostModal} fullscreen={true} >
        <Modal.Header>
          <Modal.Title>Create Host</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row style={{marginBottom:20}}>
              <Col xs={12} md={6}>
                <Form.Label htmlFor="inputPassword5">User</Form.Label>
                <Form.Select
                  value={user_id}
                  onChange={(e) => setUserId(e.target.value)}
                >
                  {userOptions}
                </Form.Select>
              </Col>
              <Col xs={12} md={6}>
                <Form.Label htmlFor="inputPassword5">City</Form.Label>
                <Form.Select
                  value={city_id}
                  onChange={(e) => setCityId(e.target.value)}
                >
                  {cityOptions}
                </Form.Select>
              </Col>
            </Row>

            <Row style={{marginBottom:20}}>
              <Col xs={21} md={6}>
                <Form.Label htmlFor="inputPassword5">First Name</Form.Label>
                <Form.Control
                  type="text"
                  value={first_name}
                  placeholder='Enter First Name'
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Col>
              <Col xs={12} md={6}>
                <Form.Label htmlFor="inputPassword5">Last Name</Form.Label>
                <Form.Control
                  type="text"
                  value={last_name}
                  placeholder='Enter Last Name'
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Col>
            </Row>

            <Row style={{marginBottom:20}}>
              <Col xs={12} md={12}>
                <Form.Label htmlFor="inputPassword5">Host Message</Form.Label>
                <Form.Control
                  type="text"
                  value={host_message}
                  as="textarea"
                  rows={5}
                  placeholder='Enter Host Message'
                  onChange={(e) => setHostMessage(e.target.value)}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateHost}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateHostModal;