import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../axiosInstance';

// fetch all hosts
export const fetchHosts = createAsyncThunk(
  'admin/fetchHosts',
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.admin.authToken; // Adjust according to your state structure

      const response = await axiosInstance.get('/admin/hosts', {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      return response.data;
    } catch (error) {
      // TO DO: Handle error properly
      alert(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// create host
export const createHost = createAsyncThunk(
  'admin/createHost',
  async (requestBody, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.admin.authToken; // Adjust according to your state structure

      const response = await axiosInstance.post('/admin/hosts', requestBody, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      return response.data;
    } catch (error) {
      // TO DO: Handle error properly
      alert(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);


// update host
export const editHost = createAsyncThunk(
  'admin/editHost',
  async (requestBody, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.admin.authToken; // Adjust according to your state structure

      const response = await axiosInstance.put(`/admin/hosts/${requestBody.id}`, requestBody, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      return response.data;
    } catch (error) {
      // TO DO: Handle error properly
      alert(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  hosts: [],
  status: 'idle',
  error: null
};

export const hostSlice = createSlice({
  name: 'host',
  initialState,
  reducers: {
    clearhosts: (state) => {
      state.hosts = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHosts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchHosts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.hosts = action.payload;
      })
      .addCase(fetchHosts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(createHost.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createHost.fulfilled, (state, action) => {
        state.hosts.push(action.payload);
      })
      .addCase(createHost.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(editHost.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editHost.fulfilled, (state, action) => {
        state.hosts = state.hosts.map((host) => {
          if (host.id === action.payload.id) {
            return action.payload;
          }
          return host;
        });
      })
      .addCase(editHost.rejected, (state, action) => {
        state.error = action.payload;
      });
  }
});

export const { clearMesssages } = hostSlice.actions;

export default hostSlice.reducer;
