

export const styles = {
  mainContainer:{
    display: "flex",
    width: "100%",
    height: 550,
    objectFit: "cover",
    backgroundColor: "pink",
    margin: 0,
    padding: 0,
    borderRadius: 10,
  },
  imageContainer:{
    display: "flex",
    width: "100%",
    display: "flex",
    backgroundColor:"black",
    margin: 0,
    padding: 0,
    borderRadius: 10,
  },
  imageStyle:{
    width: "100%",
    height: 550,
    objectFit: "cover",
    margin: 0,
    padding: 0,
  }
}