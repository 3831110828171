import React, { useState } from 'react';
import { Modal, Button, Container, Row, Col, Form } from 'react-bootstrap';
import { gameTypeDropdownData, skillLevelDropdownData } from '../../utils/dropdowns';
import './../../styles/styles.css';
import { useSelector, useDispatch  } from 'react-redux';
import { updateEvent } from '../../redux/features/event/eventSlice';
import { validateEventRequiredFields } from '../../utils/validations';
import { getTimePartForTimePicker } from '../../utils/dateUtils';

function EditEventModal({
  event,
  showEditEventModal,
  setShowEditEventModal,
}) {
  const dispatch = useDispatch();
  const locations = useSelector((state) => state.location.locations);
  const cities = useSelector((state) => state.admin.cities);
  const eventTypes = useSelector((state) => state.admin.eventTypes);
  const hosts = useSelector((state) => state.host.hosts);
  const localStartTime = getTimePartForTimePicker(event.start_time);
  const localEndTime = getTimePartForTimePicker(event.end_time);

  const [game_name, setGameName] = useState(event.game_name);
  const [event_type_id, setEventTypeId] = useState(event.event_type_id);
  const [skill_level, setSkillLevel] = useState(event.skill_level);
  const [max_players, setMaxPlayers] = useState(event.max_player);
  const [price, setPrice] = useState(event.price);
  const [locaiton_id, setLocationId] = useState(event.location_id);
  const [is_free, setIsFree] = useState(event.is_free);
  const [active, setActive] = useState(event.active);
  const [city_id, setCityId] = useState(event.city.id);
  const [host_id, setHostId] = useState(event.host.id);
  const [startTime, setStartTime] = useState(localStartTime);
  const [endTime, setEndTime] = useState(localEndTime);
  const [date, setDate] = useState(event.date);
  const [descriptionFirstTitle, setDescriptionFirstTitle] = useState(event.descriptionFirstTitle);
  const [descriptionFirstText, setDescriptionFirstText] = useState(event.descriptionFirstText);
  const [descriptionSecondTitle, setDescriptionSecondTitle] = useState(event.descriptionSecondTitle);
  const [descriptionSecondText, setDescriptionSecondText] = useState(event.descriptionSecondText);
  const [descriptionThirdTitle, setDescriptionThirdTitle] = useState(event.descriptionThirdTitle);
  const [descriptionThirdText, setDescriptionThirdText] = useState(event.descriptionThirdText);
  const [player_count, setPlayerCount] = useState(event.player_count);

  const eventTypeOptions = eventTypes.map((option) => (
    <option key={option.id} value={option.id}>
      {option.name}
    </option>
  ));

  const skillLevelOptions = skillLevelDropdownData.map((option) => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ));

  const locationOptions = locations.map((location) => (
    <option key={location.id} value={location.id}>
      {location.location_name}
    </option>
  ));

  const cityOptions = cities.map((city) => (
    <option key={city.id} value={city.id}>
      {city.name + ", " + city.state}
    </option>
  ));

  const hostOptions = hosts.map((host) => (
    <option key={host.id} value={host.id}>
      {host.first_name + " " + host.last_name}
    </option>
  ));

  const handleClose = () => {
    setShowEditEventModal(false)
  };

  const handleCreateEvent = () => {

    // Combine date and time, considering timezone if necessary
    const localStartTime = new Date(`${date}T${startTime}`);
    const localEndTime = new Date(`${date}T${endTime}`);

    const requestBody = {
      id: event.id,
      game_name: game_name,
      event_type_id: event_type_id,
      skill_level: skill_level,
      max_player: +max_players,
      price: +parseFloat(price).toFixed(2),
      location_id: +locaiton_id,
      is_free: is_free,
      active: active,
      city_id: +city_id,
      host_id: +host_id,
      date: date,
      start_time: localStartTime,
      end_time: localEndTime,
      descriptionFirstTitle: descriptionFirstTitle,
      descriptionFirstText: descriptionFirstText,
      descriptionSecondTitle: descriptionSecondTitle,
      descriptionSecondText: descriptionSecondText,
      descriptionThirdTitle: descriptionThirdTitle,
      descriptionThirdText: descriptionThirdText,
      player_count: +player_count,
    };
    // dispatch the createEvent action
    if (!validateEventRequiredFields(requestBody)) {
      return;
    }
    dispatch(updateEvent(requestBody));
    setShowEditEventModal(false);
  };


  return (
    <>
      <Modal show={showEditEventModal} fullscreen={true} >
        <Modal.Header>
          <Modal.Title>Edit Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row style={{marginBottom:20}}>
              <Col xs={6} md={4}>
                <Form.Label htmlFor="inputPassword5">Event Name</Form.Label>
                <Form.Control
                  type="text"
                  value={game_name}
                  placeholder='Enter an Event Name'
                  onChange={(e) => setGameName(e.target.value)}
                />
              </Col>
              <Col xs={6} md={4}>
                <Form.Label htmlFor="inputPassword5">Event Type</Form.Label>
                <Form.Select 
                  aria-label="Default select example"
                  value={event_type_id}
                  onChange={(e) => setEventTypeId(e.target.value)}
                >
                  <option key={0} value={""}>
                    {"Select a Event Type"}
                  </option>
                  {eventTypeOptions}
                </Form.Select>
              </Col>
              <Col xs={6} md={4}>
                <Form.Label htmlFor="inputPassword5">Skill Level</Form.Label>
                <Form.Select 
                  aria-label="Default select example"
                  value={skill_level}
                  onChange={(e) => setSkillLevel(e.target.value)}
                >
                  <option key={0} value={""}>
                    {"Select a Skill Level"}
                  </option>
                  {skillLevelOptions}
                </Form.Select>
              </Col>
            </Row>

            <Row style={{marginBottom:20}}>
              <Col xs={6} md={4}>
                <Form.Label htmlFor="inputPassword5">Max Number of Attendies</Form.Label>
                <Form.Control
                  type="number"
                  value={max_players}
                  onChange={(e) => setMaxPlayers(e.target.value)}
                />
              </Col>
              <Col xs={6} md={4}>
                <Form.Label htmlFor="inputPassword5">Price</Form.Label>
                <Form.Control
                  type="number"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </Col>
              <Col xs={6} md={4}>
                <Form.Label htmlFor="inputPassword5">Location</Form.Label>
                <Form.Select 
                  aria-label="Default select example"
                  value={locaiton_id}
                  onChange={(e) => setLocationId(e.target.value)}
                >
                  {locationOptions}
                </Form.Select>
              </Col>
            </Row>

            <Row style={{marginBottom:20}}>
              <Col xs={6} md={4}>
                <Form.Label htmlFor="inputPassword5">Host</Form.Label>
                <Form.Select 
                  aria-label="Default select example"
                  type="number"
                  value={host_id}
                  onChange={(e) => setHostId(e.target.value)}
                >
                  {hostOptions}
                </Form.Select>
              </Col>
              <Col xs={6} md={4}>
                <Form.Label htmlFor="inputPassword5">Area</Form.Label>
                <Form.Select 
                  aria-label="Default select example"
                  value={city_id}
                  onChange={(e) => setCityId(e.target.value)}
                >
                  {cityOptions}
                </Form.Select>
              </Col>
            </Row>

            <Row style={{marginBottom:20}}>
              <Col xs={6} md={4}>
                <Form.Label htmlFor="inputPassword5">Date</Form.Label>
                <Form.Control
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </Col>
              <Col xs={6} md={4}>
                <Form.Label htmlFor="inputPassword5">Start Time</Form.Label>
                <Form.Control
                  type="time"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                />
              </Col>
              <Col xs={6} md={4}>
                <Form.Label htmlFor="inputPassword5">End Time</Form.Label>
                <Form.Control
                  type="time"
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                />
              </Col>
            </Row>
            <Row style={{marginBottom:20}}>
              <Col xs={6} md={4}>
                <Form.Label htmlFor="inputPassword5">Is Free?</Form.Label>
                <Form.Check
                  type="checkbox"
                  checked={is_free}
                  onChange={(e) => setIsFree(e.target.checked)}
                />                
              </Col>
              <Col xs={6} md={4}>
                <Form.Label htmlFor="inputPassword5">Is Active?</Form.Label>
                <Form.Check
                  type="checkbox"
                  checked={active}
                  onChange={(e) => setActive(e.target.checked)}
                />
              </Col>
            </Row>
            <Row style={{marginBottom:20}}>
              <Col xs={6} md={4}>
                <Form.Label htmlFor="inputPassword5">Description Title 1</Form.Label>
                <Form.Control
                  type="text"
                  value={descriptionFirstTitle}
                  onChange={(e) => setDescriptionFirstTitle(e.target.value)}
                />
              </Col>
              <Col xs={6} md={8}>
                <Form.Label htmlFor="inputPassword5">Description Text 1</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  rows={5}
                  value={descriptionFirstText}
                  onChange={(e) => setDescriptionFirstText(e.target.value)}
                />
              </Col>
            </Row>
            <Row style={{marginBottom:20}}>
              <Col xs={6} md={4}>
                <Form.Label htmlFor="inputPassword5">Description Title 2</Form.Label>
                <Form.Control
                  type="text"
                  value={descriptionSecondTitle}
                  onChange={(e) => setDescriptionSecondTitle(e.target.value)}
                />
              </Col>
              <Col xs={6} md={8}>
                <Form.Label htmlFor="inputPassword5">Description Text 2</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  rows={5}
                  value={descriptionSecondText}
                  onChange={(e) => setDescriptionSecondText(e.target.value)}
                />
              </Col>
            </Row>
            <Row style={{marginBottom:20}}>
              <Col xs={6} md={4}>
                <Form.Label htmlFor="inputPassword5">Description Title 3</Form.Label>
                <Form.Control
                  type="text"
                  value={descriptionThirdTitle}
                  onChange={(e) => setDescriptionThirdTitle(e.target.value)}
                />
              </Col>
              <Col xs={6} md={8}>
                <Form.Label htmlFor="inputPassword5">Description Text 3</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  rows={5}
                  value={descriptionThirdText}
                  onChange={(e) => setDescriptionThirdText(e.target.value)}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateEvent}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditEventModal;