import React from 'react';
import { Container, Row } from 'react-bootstrap';
import {styles} from './WhatToDoNow.component.style';

const WhatToDoNow = ({
  images,
}) => {
 
  return(
    <Container fluid>
      <Row>
        <Container fluid style={styles.headerRow}>
          <p style={styles.headerText}>WHAT TO DO NOW!</p>
        </Container>
      </Row>
      <Row>
        <Container fluid style={styles.mainContainer}>
          <Container fluid style={styles.childContainer}>
            <Container>
              <p style={styles.itemHeader}>Dowload The App</p>
              <p style={styles.itemText}>Download Eventyol App from Apple Store or Google Play Store and create your account</p>
            </Container>
            <Container style={{ textAlign:"center"}}>
              <img src={images.homePageImage} style={{width:"80%", height:"100%", maxWidth:400}}/>
            </Container>
          </Container>
          <Container fluid style={styles.childContainer}>
            <Container>
              <p style={styles.itemHeader}>Explore</p>
              <p style={styles.itemText}>Explore the events and activities happening around you and register for an event</p>
            </Container>
            <Container style={{ textAlign:"center"}}>
              <img src={images.mapPageImage} style={{width:"80%", height:"100%", maxWidth:400 }}/>
            </Container>
          </Container>
          <Container fluid style={styles.childContainer}>
          < Container>
              <p style={styles.itemHeader}>Have Fun!</p>
              <p style={styles.itemText}>Attend the event and have fun with your friends</p>
            </Container>
            <Container style={{ textAlign:"center"}}>
              <img src={images.eventPageImage} style={{width:"80%", height:"100%", maxWidth:400}}/>
            </Container>
          </Container>
        </Container>
      </Row>
    </Container>
  )
}


export default WhatToDoNow;