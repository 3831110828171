import React from 'react';
const campingRecImage2 = require('./../../assets/camping_rec_2.jpg');

const AboutUs = () => {
  return(
    <div style={{
      position: "relative",
      height: "100vh",
      color: "white",
      textAlign: "center",
      fontSize: 30,
      fontWeight: "bold",
      padding: 20,
      paddingTop: "5%",
      paddingBottom: "5%",
      backgroundImage: `url(${campingRecImage2})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat"
    }}>
      <div style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "black",
        opacity: 0.5, // Adjust the opacity as needed
        zIndex: 1
      }}></div>
      <div style={{ position: "relative", zIndex: 2, paddingTop:"10%" }}>
        <p>
          About Us
        </p>
        <div>
          <p style={{fontSize:15, paddingLeft:"10%", paddingRight:"10%"}}>
            Eventyol is a dynamic mobile app designed to bring people together through shared interests in sports, events, and pick-up games. We believe that community connections thrive through real-world experiences, and our platform makes it easy for users to discover and join events in their area. Whether you're looking to stay active, meet new people, or try something new, Eventyol is here to help you find events that match your interests. Join us and be part of a growing community that values fun, fitness, and friendship!
          </p>
        </div>
      </div>
    </div>   
  )
}


export default AboutUs;