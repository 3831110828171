export const colors = {
  icon_green: '#01d0d8',
  button_orange: "#fe666b",
  dark_blue: "#002C4F",
  light_blue: "#5abbdd",
  white: "#ffffff",
  blue: "#2196f3",
  grey: "#f6faff",
  black: "#000000",
  light_grey:"#D3D3D3",
};