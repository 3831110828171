import { combineReducers } from '@reduxjs/toolkit';
import adminReducer from './features/admin/adminSlice'; // Adjust the import according to your project structure
import recommendationReducer from './features/recommendation/recommendationSlice'; // Adjust the import according to your project structure
import messageSlice from './features/message/messageSlice';
import userSlice  from './features/user/userSlice';
import locationSlice from './features/location/locationSlice';
import eventSlice from './features/event/eventSlice';
import hostSlice from './features/host/hostSlice';

const rootReducer = combineReducers({
  admin: adminReducer,
  recommendation: recommendationReducer,
  message: messageSlice,
  user: userSlice,
  location: locationSlice,
  event: eventSlice,
  host: hostSlice,
  // Add other reducers here
});

export default rootReducer;