import { colors } from '../../constants/colors';

export const styles = {
  mainContainer:{
    display: "flex",
    flexWrap: "wrap",
    justifyContent: 'space-between',
    margin: 0,
    paddingBottom: 50,
    backgroundColor: colors.white,
    width: '100%',
  },
  childContainer :{
    width: '50%',
    minWidth: 300,
    flex:1,
  },
  container: {
    backgroundColor: colors.dark_blue,
    paddingTop: 40,
    borderRadius: 10,
    paddingBottom: 30,
    height:550,
    width: '100%',
  },
  headerRow: {
    color: 'black',
    fontSize: 16,
    alignItems: 'center',
    marginTop: 40,
  },
  headerText: {
    color: colors.dark_blue,
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 10,
  },
  itemHeader: {
    color: colors.dark_blue,
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 20,
  },
  itemText: {
    color: colors.black,
    fontSize: 14,
    textAlign: 'center',
    minHeight: 70,
  },
}